import React, { Component } from "react"
import { Container } from "reactstrap"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import {
  Banner,
  PaddingWrapper,
  ReferencesFull,
  TitleH2Center,
  Button,
  ButtonStyle,
} from "@igloonet-web/shared-ui"

import LogoDoktorKladivo from "../images/reference/doktor-kladivo/logo-doktorkladivo.png"
import LogoFenstar from "../images/reference/fenstar/logo.svg"
import LogoFSI from "../images/reference/fsivut/logofsi.png"
import LogoHelcel from "../images/reference/helcel/logohelcel.png"
import LogoIFE from "../images/reference/ife/logoife.png"
import LogoKiwi from "../images/reference/kiwi/logo.png"
import LogoPanvicky from "../images/reference/panvicky/logo-panvicky.png"
import LogoPlaneta from "../images/reference/planetaher/logo-planeta.png"
import LogoRoyalCanin from "../images/reference/royal-canin/logo-rc.png"
import LogoSZ from "../images/reference/snowboard-zezula/logo-sz.png"
import logoGrohe from "../images/reference/grohe/logo.png"
import logoHannah from "../images/reference/hannah/logo.png"
import logoSib from "../images/reference/study-in-brno/logo.svg"

class Reference extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const marketingReferences = [
      {
        href: "/marketing/reference/kiwi",
        image: LogoKiwi,
        alt: "Logo Kiwi.com",
        name: "Kiwi.com",
      },
      {
        href: "/marketing/reference/fsivut",
        image: LogoFSI,
        alt: "Logo FSI VUT",
        name: "FSI VUT",
      },
      {
        href: "/marketing/reference/helcel",
        image: LogoHelcel,
        alt: "Logo HELCEL ",
        name: "HELCEL",
      },
      {
        href: "/marketing/reference/ife",
        image: LogoIFE,
        alt: "Logo IFE",
        name: "IFE-CR",
      },
      {
        href: "/marketing/reference/hannah",
        image: logoHannah,
        alt: "Logo Hannah",
        name: "Hannah",
      },
      {
        href: "/marketing/reference/planetaher",
        image: LogoPlaneta,
        alt: "Logo planetaher.cz",
        name: "Planetaher.cz",
      },
      {
        href: "/marketing/reference/grohe",
        image: logoGrohe,
        alt: "Logo Grohe",
        name: "Grohe",
      },
      {
        href: "/marketing/reference/study-in-brno",
        image: logoSib,
        title: "study-in-brno.cz",
        name: "Study in Brno",
      },
    ]

    const vyvojReferences = [
      {
        href: "/vyvoj/reference/royal-canin",
        image: LogoRoyalCanin,
        alt: "Logo Royal Canin",
        name: "Royal Canin",
      },
      {
        href: "/vyvoj/reference/doktor-kladivo",
        image: LogoDoktorKladivo,
        alt: "Logo doktorkladivo.cz",
        name: "DoktorKladivo.cz",
      },
      {
        href: "/vyvoj/reference/fenstar",
        image: LogoFenstar,
        alt: "Nápis FenStar ve zlaté barvě",
        name: "FenStar",
      },
      {
        href: "/vyvoj/reference/panvicky",
        image: LogoPanvicky,
        alt: "Logo panvicky.cz",
        name: "Panvicky.cz",
      },
      {
        href: "/vyvoj/reference/snowboard-zezula",
        image: LogoSZ,
        alt: "Logo snowboard-zezula.cz",
        name: "SNOWBOARD ZEZULA",
      },
      {
        href: "/vyvoj/reference/planetaher",
        image: LogoPlaneta,
        alt: "Logo planetaher.cz",
        name: "Planetaher.cz",
      },
    ]

    return (
      <Layout>
        <Helmet>
          <title>Reference | igloonet</title>
          <meta
            name="description"
            content="Reference na zajímavé zakázky, které jsme realizovali pro naše klienty."
          />
        </Helmet>
        <Banner>
          <h1>Reference</h1>
          <p>Připojte se k našim spokojeným zákazníkům</p>
        </Banner>

        <Container>
          <PaddingWrapper>
            <TitleH2Center>Online marketing</TitleH2Center>
            <ReferencesFull anotherReferences={marketingReferences} />
            <div className="text-center">
              <Button to="/marketing/reference" style={ButtonStyle.Primary}>
                Všechny marketingové reference
              </Button>
            </div>
          </PaddingWrapper>

          <PaddingWrapper>
            <TitleH2Center>Vývoj webů</TitleH2Center>
            <ReferencesFull anotherReferences={vyvojReferences} />
            <div className="text-center">
              <Button to="/vyvoj/reference" style={ButtonStyle.Primary}>
                Všechny reference na vývoj
              </Button>
            </div>
          </PaddingWrapper>
        </Container>
      </Layout>
    )
  }
}

export default Reference
